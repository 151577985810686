exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-credits-js": () => import("./../../../src/pages/credits.js" /* webpackChunkName: "component---src-pages-credits-js" */),
  "component---src-pages-instruccions-js": () => import("./../../../src/pages/instruccions.js" /* webpackChunkName: "component---src-pages-instruccions-js" */),
  "component---src-pages-jocs-index-js": () => import("./../../../src/pages/jocs/index.js" /* webpackChunkName: "component---src-pages-jocs-index-js" */),
  "component---src-templates-joc-js-content-file-path-jocs-2023-09-10-mdx": () => import("./../../../src/templates/joc.js?__contentFilePath=/opt/buildhome/repo/jocs/2023-09-10.mdx" /* webpackChunkName: "component---src-templates-joc-js-content-file-path-jocs-2023-09-10-mdx" */),
  "component---src-templates-joc-js-content-file-path-jocs-2023-09-17-mdx": () => import("./../../../src/templates/joc.js?__contentFilePath=/opt/buildhome/repo/jocs/2023-09-17.mdx" /* webpackChunkName: "component---src-templates-joc-js-content-file-path-jocs-2023-09-17-mdx" */),
  "component---src-templates-joc-js-content-file-path-jocs-2023-09-24-mdx": () => import("./../../../src/templates/joc.js?__contentFilePath=/opt/buildhome/repo/jocs/2023-09-24.mdx" /* webpackChunkName: "component---src-templates-joc-js-content-file-path-jocs-2023-09-24-mdx" */),
  "component---src-templates-joc-js-content-file-path-jocs-2023-10-01-mdx": () => import("./../../../src/templates/joc.js?__contentFilePath=/opt/buildhome/repo/jocs/2023-10-01.mdx" /* webpackChunkName: "component---src-templates-joc-js-content-file-path-jocs-2023-10-01-mdx" */),
  "component---src-templates-joc-js-content-file-path-jocs-2023-10-08-mdx": () => import("./../../../src/templates/joc.js?__contentFilePath=/opt/buildhome/repo/jocs/2023-10-08.mdx" /* webpackChunkName: "component---src-templates-joc-js-content-file-path-jocs-2023-10-08-mdx" */),
  "component---src-templates-joc-js-content-file-path-jocs-2023-10-15-mdx": () => import("./../../../src/templates/joc.js?__contentFilePath=/opt/buildhome/repo/jocs/2023-10-15.mdx" /* webpackChunkName: "component---src-templates-joc-js-content-file-path-jocs-2023-10-15-mdx" */),
  "component---src-templates-joc-js-content-file-path-jocs-2023-10-22-mdx": () => import("./../../../src/templates/joc.js?__contentFilePath=/opt/buildhome/repo/jocs/2023-10-22.mdx" /* webpackChunkName: "component---src-templates-joc-js-content-file-path-jocs-2023-10-22-mdx" */),
  "component---src-templates-joc-js-content-file-path-jocs-2023-10-29-mdx": () => import("./../../../src/templates/joc.js?__contentFilePath=/opt/buildhome/repo/jocs/2023-10-29.mdx" /* webpackChunkName: "component---src-templates-joc-js-content-file-path-jocs-2023-10-29-mdx" */),
  "component---src-templates-joc-js-content-file-path-jocs-2023-11-05-mdx": () => import("./../../../src/templates/joc.js?__contentFilePath=/opt/buildhome/repo/jocs/2023-11-05.mdx" /* webpackChunkName: "component---src-templates-joc-js-content-file-path-jocs-2023-11-05-mdx" */),
  "component---src-templates-joc-js-content-file-path-jocs-2023-11-12-mdx": () => import("./../../../src/templates/joc.js?__contentFilePath=/opt/buildhome/repo/jocs/2023-11-12.mdx" /* webpackChunkName: "component---src-templates-joc-js-content-file-path-jocs-2023-11-12-mdx" */),
  "component---src-templates-joc-js-content-file-path-jocs-2023-11-19-mdx": () => import("./../../../src/templates/joc.js?__contentFilePath=/opt/buildhome/repo/jocs/2023-11-19.mdx" /* webpackChunkName: "component---src-templates-joc-js-content-file-path-jocs-2023-11-19-mdx" */),
  "component---src-templates-joc-js-content-file-path-jocs-2023-11-26-mdx": () => import("./../../../src/templates/joc.js?__contentFilePath=/opt/buildhome/repo/jocs/2023-11-26.mdx" /* webpackChunkName: "component---src-templates-joc-js-content-file-path-jocs-2023-11-26-mdx" */),
  "component---src-templates-joc-js-content-file-path-jocs-2023-12-03-mdx": () => import("./../../../src/templates/joc.js?__contentFilePath=/opt/buildhome/repo/jocs/2023-12-03.mdx" /* webpackChunkName: "component---src-templates-joc-js-content-file-path-jocs-2023-12-03-mdx" */),
  "component---src-templates-joc-js-content-file-path-jocs-2023-12-10-mdx": () => import("./../../../src/templates/joc.js?__contentFilePath=/opt/buildhome/repo/jocs/2023-12-10.mdx" /* webpackChunkName: "component---src-templates-joc-js-content-file-path-jocs-2023-12-10-mdx" */),
  "component---src-templates-joc-js-content-file-path-jocs-2023-12-17-mdx": () => import("./../../../src/templates/joc.js?__contentFilePath=/opt/buildhome/repo/jocs/2023-12-17.mdx" /* webpackChunkName: "component---src-templates-joc-js-content-file-path-jocs-2023-12-17-mdx" */),
  "component---src-templates-joc-js-content-file-path-jocs-2023-12-24-mdx": () => import("./../../../src/templates/joc.js?__contentFilePath=/opt/buildhome/repo/jocs/2023-12-24.mdx" /* webpackChunkName: "component---src-templates-joc-js-content-file-path-jocs-2023-12-24-mdx" */),
  "component---src-templates-joc-js-content-file-path-jocs-2023-12-31-mdx": () => import("./../../../src/templates/joc.js?__contentFilePath=/opt/buildhome/repo/jocs/2023-12-31.mdx" /* webpackChunkName: "component---src-templates-joc-js-content-file-path-jocs-2023-12-31-mdx" */),
  "component---src-templates-joc-js-content-file-path-jocs-2024-01-07-mdx": () => import("./../../../src/templates/joc.js?__contentFilePath=/opt/buildhome/repo/jocs/2024-01-07.mdx" /* webpackChunkName: "component---src-templates-joc-js-content-file-path-jocs-2024-01-07-mdx" */),
  "component---src-templates-joc-js-content-file-path-jocs-2024-01-14-mdx": () => import("./../../../src/templates/joc.js?__contentFilePath=/opt/buildhome/repo/jocs/2024-01-14.mdx" /* webpackChunkName: "component---src-templates-joc-js-content-file-path-jocs-2024-01-14-mdx" */),
  "component---src-templates-joc-js-content-file-path-jocs-2024-01-21-mdx": () => import("./../../../src/templates/joc.js?__contentFilePath=/opt/buildhome/repo/jocs/2024-01-21.mdx" /* webpackChunkName: "component---src-templates-joc-js-content-file-path-jocs-2024-01-21-mdx" */),
  "component---src-templates-joc-js-content-file-path-jocs-2024-01-28-mdx": () => import("./../../../src/templates/joc.js?__contentFilePath=/opt/buildhome/repo/jocs/2024-01-28.mdx" /* webpackChunkName: "component---src-templates-joc-js-content-file-path-jocs-2024-01-28-mdx" */),
  "component---src-templates-joc-js-content-file-path-jocs-2024-02-04-mdx": () => import("./../../../src/templates/joc.js?__contentFilePath=/opt/buildhome/repo/jocs/2024-02-04.mdx" /* webpackChunkName: "component---src-templates-joc-js-content-file-path-jocs-2024-02-04-mdx" */),
  "component---src-templates-joc-js-content-file-path-jocs-2024-02-11-mdx": () => import("./../../../src/templates/joc.js?__contentFilePath=/opt/buildhome/repo/jocs/2024-02-11.mdx" /* webpackChunkName: "component---src-templates-joc-js-content-file-path-jocs-2024-02-11-mdx" */),
  "component---src-templates-joc-js-content-file-path-jocs-2024-02-18-mdx": () => import("./../../../src/templates/joc.js?__contentFilePath=/opt/buildhome/repo/jocs/2024-02-18.mdx" /* webpackChunkName: "component---src-templates-joc-js-content-file-path-jocs-2024-02-18-mdx" */),
  "component---src-templates-joc-js-content-file-path-jocs-2024-02-25-mdx": () => import("./../../../src/templates/joc.js?__contentFilePath=/opt/buildhome/repo/jocs/2024-02-25.mdx" /* webpackChunkName: "component---src-templates-joc-js-content-file-path-jocs-2024-02-25-mdx" */),
  "component---src-templates-joc-js-content-file-path-jocs-2024-03-03-mdx": () => import("./../../../src/templates/joc.js?__contentFilePath=/opt/buildhome/repo/jocs/2024-03-03.mdx" /* webpackChunkName: "component---src-templates-joc-js-content-file-path-jocs-2024-03-03-mdx" */),
  "component---src-templates-joc-js-content-file-path-jocs-2024-03-10-mdx": () => import("./../../../src/templates/joc.js?__contentFilePath=/opt/buildhome/repo/jocs/2024-03-10.mdx" /* webpackChunkName: "component---src-templates-joc-js-content-file-path-jocs-2024-03-10-mdx" */),
  "component---src-templates-joc-js-content-file-path-jocs-2024-03-17-mdx": () => import("./../../../src/templates/joc.js?__contentFilePath=/opt/buildhome/repo/jocs/2024-03-17.mdx" /* webpackChunkName: "component---src-templates-joc-js-content-file-path-jocs-2024-03-17-mdx" */),
  "component---src-templates-joc-js-content-file-path-jocs-2024-03-24-mdx": () => import("./../../../src/templates/joc.js?__contentFilePath=/opt/buildhome/repo/jocs/2024-03-24.mdx" /* webpackChunkName: "component---src-templates-joc-js-content-file-path-jocs-2024-03-24-mdx" */),
  "component---src-templates-joc-js-content-file-path-jocs-2024-03-31-mdx": () => import("./../../../src/templates/joc.js?__contentFilePath=/opt/buildhome/repo/jocs/2024-03-31.mdx" /* webpackChunkName: "component---src-templates-joc-js-content-file-path-jocs-2024-03-31-mdx" */),
  "component---src-templates-joc-js-content-file-path-jocs-2024-04-07-mdx": () => import("./../../../src/templates/joc.js?__contentFilePath=/opt/buildhome/repo/jocs/2024-04-07.mdx" /* webpackChunkName: "component---src-templates-joc-js-content-file-path-jocs-2024-04-07-mdx" */),
  "component---src-templates-joc-js-content-file-path-jocs-2024-04-14-mdx": () => import("./../../../src/templates/joc.js?__contentFilePath=/opt/buildhome/repo/jocs/2024-04-14.mdx" /* webpackChunkName: "component---src-templates-joc-js-content-file-path-jocs-2024-04-14-mdx" */),
  "component---src-templates-joc-js-content-file-path-jocs-2024-04-21-mdx": () => import("./../../../src/templates/joc.js?__contentFilePath=/opt/buildhome/repo/jocs/2024-04-21.mdx" /* webpackChunkName: "component---src-templates-joc-js-content-file-path-jocs-2024-04-21-mdx" */),
  "component---src-templates-joc-js-content-file-path-jocs-2024-04-23-mdx": () => import("./../../../src/templates/joc.js?__contentFilePath=/opt/buildhome/repo/jocs/2024-04-23.mdx" /* webpackChunkName: "component---src-templates-joc-js-content-file-path-jocs-2024-04-23-mdx" */),
  "component---src-templates-joc-js-content-file-path-jocs-2024-04-28-mdx": () => import("./../../../src/templates/joc.js?__contentFilePath=/opt/buildhome/repo/jocs/2024-04-28.mdx" /* webpackChunkName: "component---src-templates-joc-js-content-file-path-jocs-2024-04-28-mdx" */),
  "component---src-templates-joc-js-content-file-path-jocs-2024-05-05-mdx": () => import("./../../../src/templates/joc.js?__contentFilePath=/opt/buildhome/repo/jocs/2024-05-05.mdx" /* webpackChunkName: "component---src-templates-joc-js-content-file-path-jocs-2024-05-05-mdx" */),
  "component---src-templates-joc-js-content-file-path-jocs-2024-05-12-mdx": () => import("./../../../src/templates/joc.js?__contentFilePath=/opt/buildhome/repo/jocs/2024-05-12.mdx" /* webpackChunkName: "component---src-templates-joc-js-content-file-path-jocs-2024-05-12-mdx" */),
  "component---src-templates-joc-js-content-file-path-jocs-2024-05-19-mdx": () => import("./../../../src/templates/joc.js?__contentFilePath=/opt/buildhome/repo/jocs/2024-05-19.mdx" /* webpackChunkName: "component---src-templates-joc-js-content-file-path-jocs-2024-05-19-mdx" */),
  "component---src-templates-joc-js-content-file-path-jocs-2024-05-26-mdx": () => import("./../../../src/templates/joc.js?__contentFilePath=/opt/buildhome/repo/jocs/2024-05-26.mdx" /* webpackChunkName: "component---src-templates-joc-js-content-file-path-jocs-2024-05-26-mdx" */),
  "component---src-templates-joc-js-content-file-path-jocs-2024-06-02-mdx": () => import("./../../../src/templates/joc.js?__contentFilePath=/opt/buildhome/repo/jocs/2024-06-02.mdx" /* webpackChunkName: "component---src-templates-joc-js-content-file-path-jocs-2024-06-02-mdx" */),
  "component---src-templates-joc-js-content-file-path-jocs-2024-06-09-mdx": () => import("./../../../src/templates/joc.js?__contentFilePath=/opt/buildhome/repo/jocs/2024-06-09.mdx" /* webpackChunkName: "component---src-templates-joc-js-content-file-path-jocs-2024-06-09-mdx" */),
  "component---src-templates-joc-js-content-file-path-jocs-2024-06-16-mdx": () => import("./../../../src/templates/joc.js?__contentFilePath=/opt/buildhome/repo/jocs/2024-06-16.mdx" /* webpackChunkName: "component---src-templates-joc-js-content-file-path-jocs-2024-06-16-mdx" */),
  "component---src-templates-joc-js-content-file-path-jocs-2024-06-23-mdx": () => import("./../../../src/templates/joc.js?__contentFilePath=/opt/buildhome/repo/jocs/2024-06-23.mdx" /* webpackChunkName: "component---src-templates-joc-js-content-file-path-jocs-2024-06-23-mdx" */),
  "component---src-templates-joc-js-content-file-path-jocs-2024-06-30-mdx": () => import("./../../../src/templates/joc.js?__contentFilePath=/opt/buildhome/repo/jocs/2024-06-30.mdx" /* webpackChunkName: "component---src-templates-joc-js-content-file-path-jocs-2024-06-30-mdx" */),
  "component---src-templates-joc-js-content-file-path-jocs-2024-07-07-mdx": () => import("./../../../src/templates/joc.js?__contentFilePath=/opt/buildhome/repo/jocs/2024-07-07.mdx" /* webpackChunkName: "component---src-templates-joc-js-content-file-path-jocs-2024-07-07-mdx" */),
  "component---src-templates-joc-js-content-file-path-jocs-2024-07-14-mdx": () => import("./../../../src/templates/joc.js?__contentFilePath=/opt/buildhome/repo/jocs/2024-07-14.mdx" /* webpackChunkName: "component---src-templates-joc-js-content-file-path-jocs-2024-07-14-mdx" */),
  "component---src-templates-joc-js-content-file-path-jocs-2024-07-21-mdx": () => import("./../../../src/templates/joc.js?__contentFilePath=/opt/buildhome/repo/jocs/2024-07-21.mdx" /* webpackChunkName: "component---src-templates-joc-js-content-file-path-jocs-2024-07-21-mdx" */),
  "component---src-templates-joc-js-content-file-path-jocs-2024-07-28-mdx": () => import("./../../../src/templates/joc.js?__contentFilePath=/opt/buildhome/repo/jocs/2024-07-28.mdx" /* webpackChunkName: "component---src-templates-joc-js-content-file-path-jocs-2024-07-28-mdx" */),
  "component---src-templates-joc-js-content-file-path-jocs-2024-08-04-mdx": () => import("./../../../src/templates/joc.js?__contentFilePath=/opt/buildhome/repo/jocs/2024-08-04.mdx" /* webpackChunkName: "component---src-templates-joc-js-content-file-path-jocs-2024-08-04-mdx" */),
  "component---src-templates-joc-js-content-file-path-jocs-2024-08-11-mdx": () => import("./../../../src/templates/joc.js?__contentFilePath=/opt/buildhome/repo/jocs/2024-08-11.mdx" /* webpackChunkName: "component---src-templates-joc-js-content-file-path-jocs-2024-08-11-mdx" */),
  "component---src-templates-joc-js-content-file-path-jocs-2024-08-18-mdx": () => import("./../../../src/templates/joc.js?__contentFilePath=/opt/buildhome/repo/jocs/2024-08-18.mdx" /* webpackChunkName: "component---src-templates-joc-js-content-file-path-jocs-2024-08-18-mdx" */),
  "component---src-templates-joc-js-content-file-path-jocs-2024-08-25-mdx": () => import("./../../../src/templates/joc.js?__contentFilePath=/opt/buildhome/repo/jocs/2024-08-25.mdx" /* webpackChunkName: "component---src-templates-joc-js-content-file-path-jocs-2024-08-25-mdx" */),
  "component---src-templates-joc-js-content-file-path-jocs-2024-09-01-mdx": () => import("./../../../src/templates/joc.js?__contentFilePath=/opt/buildhome/repo/jocs/2024-09-01.mdx" /* webpackChunkName: "component---src-templates-joc-js-content-file-path-jocs-2024-09-01-mdx" */),
  "component---src-templates-joc-js-content-file-path-jocs-2024-09-08-mdx": () => import("./../../../src/templates/joc.js?__contentFilePath=/opt/buildhome/repo/jocs/2024-09-08.mdx" /* webpackChunkName: "component---src-templates-joc-js-content-file-path-jocs-2024-09-08-mdx" */),
  "component---src-templates-joc-js-content-file-path-jocs-2024-09-15-mdx": () => import("./../../../src/templates/joc.js?__contentFilePath=/opt/buildhome/repo/jocs/2024-09-15.mdx" /* webpackChunkName: "component---src-templates-joc-js-content-file-path-jocs-2024-09-15-mdx" */),
  "component---src-templates-joc-js-content-file-path-jocs-2024-09-22-mdx": () => import("./../../../src/templates/joc.js?__contentFilePath=/opt/buildhome/repo/jocs/2024-09-22.mdx" /* webpackChunkName: "component---src-templates-joc-js-content-file-path-jocs-2024-09-22-mdx" */),
  "component---src-templates-joc-js-content-file-path-jocs-2024-09-29-mdx": () => import("./../../../src/templates/joc.js?__contentFilePath=/opt/buildhome/repo/jocs/2024-09-29.mdx" /* webpackChunkName: "component---src-templates-joc-js-content-file-path-jocs-2024-09-29-mdx" */),
  "component---src-templates-joc-js-content-file-path-jocs-2024-10-06-mdx": () => import("./../../../src/templates/joc.js?__contentFilePath=/opt/buildhome/repo/jocs/2024-10-06.mdx" /* webpackChunkName: "component---src-templates-joc-js-content-file-path-jocs-2024-10-06-mdx" */),
  "component---src-templates-joc-js-content-file-path-jocs-2024-10-13-mdx": () => import("./../../../src/templates/joc.js?__contentFilePath=/opt/buildhome/repo/jocs/2024-10-13.mdx" /* webpackChunkName: "component---src-templates-joc-js-content-file-path-jocs-2024-10-13-mdx" */),
  "component---src-templates-joc-js-content-file-path-jocs-2024-10-20-mdx": () => import("./../../../src/templates/joc.js?__contentFilePath=/opt/buildhome/repo/jocs/2024-10-20.mdx" /* webpackChunkName: "component---src-templates-joc-js-content-file-path-jocs-2024-10-20-mdx" */),
  "component---src-templates-joc-js-content-file-path-jocs-2024-10-27-mdx": () => import("./../../../src/templates/joc.js?__contentFilePath=/opt/buildhome/repo/jocs/2024-10-27.mdx" /* webpackChunkName: "component---src-templates-joc-js-content-file-path-jocs-2024-10-27-mdx" */),
  "component---src-templates-joc-js-content-file-path-jocs-2024-11-03-mdx": () => import("./../../../src/templates/joc.js?__contentFilePath=/opt/buildhome/repo/jocs/2024-11-03.mdx" /* webpackChunkName: "component---src-templates-joc-js-content-file-path-jocs-2024-11-03-mdx" */),
  "component---src-templates-joc-js-content-file-path-jocs-2024-11-10-mdx": () => import("./../../../src/templates/joc.js?__contentFilePath=/opt/buildhome/repo/jocs/2024-11-10.mdx" /* webpackChunkName: "component---src-templates-joc-js-content-file-path-jocs-2024-11-10-mdx" */),
  "component---src-templates-joc-js-content-file-path-jocs-2024-11-17-mdx": () => import("./../../../src/templates/joc.js?__contentFilePath=/opt/buildhome/repo/jocs/2024-11-17.mdx" /* webpackChunkName: "component---src-templates-joc-js-content-file-path-jocs-2024-11-17-mdx" */)
}

